import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const postLog = async (accessToken,formData) => {
  console.log(formData);
  const config = {
    url: `${apiServerUrl}/api/logbook/add`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};